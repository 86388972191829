
.book-appointment-details {
  h2 {
    font-size: 21px;
  }
  .type-select, .date-picker, .contact-info {
      margin: 0 0 26px;
      select {
        font-size: 12px;

        width: 100%;
        height: 40px;
        padding: 0 50px 0 15px;

        cursor: pointer;

        color: #242424;
        //background: #fff url("../../assets/img/icon-img/cart.png") no-repeat scroll right 18px center;
        border: 1px solid #ebebeb;
        box-shadow: none;

        -webkit-appearance: none;
        -moz-appearance: none;
      }
      input {
        height: 40px;

        border: 1px solid #ebebeb;
        background: #fff none repeat scroll 0 0;
      }
  }

  button.book-btn-2 {
    font-size: 14px;
    font-weight: 500;

    padding: 13px 42px 12px;

    cursor: pointer;
    transition: all 0.3s ease 0s;
    text-transform: uppercase;

    color: #fff;
    border: medium none;
    border-radius: 50px;
    background-color: $theme-color;
    &:hover {
      background-color: #333;
    }
  }
}
